import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  selectFile: boolean,
  url: any
  selectedDocs: any[],
  copySelectedDocs: any[],
  responseData: any[],
  value: number,
  indexes: number,
  allKeywords: any[],
  checkedKeyword: boolean,
  checkedKeywordIds: any[],
  keywordText: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class DocController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getApiId = ""
    postApiId = ""
    getAllKeywordsApiId = ""
    keywordRemoveCallId = ""
    keywordAddId = ""
    
   // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

      this.state = {
        // Customizable Area Start
        selectFile: false,
        url: '',
        selectedDocs: [],
        copySelectedDocs: [],
        responseData: [],
        value: 0,
        indexes: 0,
        allKeywords: [],
        checkedKeyword: false,
        checkedKeywordIds: [],
        keywordText: ''
        
        // Customizable Area End
      };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async componentWillMount() {
    // Customizable Area Start
    
   // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId === this.getApiId) {
        if(responseJson) {
          this.setState({responseData: responseJson.data.attributes.documents})
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (apiRequestCallId === this.postApiId) {
        if(responseJson) {
          console.log("post api response", responseJson.data.attributes.documents)
          this.setState({responseData: responseJson.data.attributes.documents})
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (apiRequestCallId === this.getAllKeywordsApiId) {
        this.handleKeywordsResponse(responseJson);
      }
      if ((apiRequestCallId === this.keywordRemoveCallId) || (apiRequestCallId === this.keywordAddId)) {
        this.getAllKeywords();
      }
    }
  // Customizable Area End
  }


  // Customizable Area Start

  handleTextChange = (event: any) => {
    this.setState({ keywordText: event.target.value });
  };

  handleDownload = async (event: any) => {
    console.log(this.state.responseData)
    const fileUrls = this.state.responseData.map((file)=> {
      return{
          url:file.url, 
          name:file.file_name
      }
    })

    for (const fileUrl of fileUrls) {
      const cacheBuster = `?cacheBuster=${Date.now()}`;
      const urlWithCacheBuster = `${fileUrl.url}${cacheBuster}`
      const response = await fetch(urlWithCacheBuster);
      const blob = await response.blob();
  
      // Create an anchor element with the download attribute set to the file name
      const anchor = document.createElement('a');
      anchor.download = fileUrl.url.split('/').pop();
      anchor.href = window.URL.createObjectURL(blob);
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
  
      // Click on the anchor element to initiate the download
      anchor.click();
  
      // Remove the anchor element from the DOM
      document.body.removeChild(anchor);
    }
    
  }

  handleKeywordAddition = (event?: any) => {
    if (event.key === 'Enter' || event === 'applied') {
      const keyword = this.state.keywordText.split(',');
      this.setState({ keywordText: '' });
      let token = localStorage.getItem("loginSuccessToken")
      console.log('token access....', token);
      const header = {
        "Content-Type": "application/json",
        "token": token
      };
      const attrs = {
        name: keyword
      };
      const data = {
        type: "keyword",
        attributes: attrs,
      };
      const httpBody = {
        data: data
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.keywordAddId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_keywordsearch/keywords`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  }

  handleKeywordRemove = (Item:any) => {
    let removeToken = localStorage.getItem("loginSuccessToken")
     const header = {
      "Content-Type": "application/json",
      "token": removeToken
    };
   
    const data = {
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.keywordRemoveCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_keywordsearch/keywords/${Item}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
}

    removeExtension = (filename: string) => {
      const name = filename?.substring(0, filename.lastIndexOf('.'));
      return name?.length > 15 ? name.substring(0, 15) : name;
    } 


    handleKeywordsResponse = (responseJson: any) => {
      if(responseJson) {
        const tempAllKeywords = responseJson.data.map((item: { id: any; name: any; }) => {
          return {
            id:item.id,
            name:item.name,
            checked: Boolean(this.state.allKeywords.find(el => el.id === item.id)?.checked)
          }
        });
        this.setState({allKeywords: tempAllKeywords })
        this.setState({ checkedKeyword: tempAllKeywords.every((el: { checked: any; }) => el.checked) });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }

    handleChange = (e: any, newValue: any) => {
      this.setState({value: newValue});
    };

    onChangeCheckbox = (event: any, id: any) => {
      const tempAllKeywords =this.state.allKeywords.map(item=>{
         if(item.id === id){
            return {
              ...item,
              checked: !item.checked
            }
        }else{
            return item
          }
        }) 
      this.setState({ allKeywords: tempAllKeywords});
      this.setState({ checkedKeyword: tempAllKeywords.every(el => el.checked) });
    }

    onChangeSelectAllCheckbox = (event: any) => {
      this.setState({ checkedKeyword: event.target.checked });
      this.setState({ allKeywords: this.state.allKeywords.map(item=>{
          return {
            ...item,
            checked: event.target.checked
          }
    }) });
     
    }

    onFileChange = (e: any) => {
      this.onUploadFile(e.target.files);
    };
  
    onUploadFile = (file: any) => {
      const formData = new FormData();
     
      for (let i = 0; i < file.length; i++) {
           formData.append('data[documents][]', file[i]);
      }
    
      const header = {
        token: localStorage.getItem("loginSuccessToken"),
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.postApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.uploadFile
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    };

    async componentDidMount() {
      this.getUploadedFile();
      this.getAllKeywords();
    }   

     getUploadedFile = () => {
    
      const header = {
        token: localStorage.getItem("loginSuccessToken"),
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getFileUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
      
    };

    getAllKeywords = () => {
      const header = {
        token: localStorage.getItem("loginSuccessToken"),
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getAllKeywordsApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.allKeyworeds
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };

   // Customizable Area End

}