import React from "react";

import {
    Container,
    Box,
    Paper,
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    IconButton,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TextField from '@material-ui/core/TextField';
export const Logo = require("../../global_assets/Logos.png");
import { Visibility, VisibilityOff } from "@material-ui/icons";
export const LoginImg = require("../../global_assets/Logo.png");

// Customizable Area End
import LoginAccountController, {
    Props,
} from "./LoginAccountController.web";

export default class LoginAccount extends LoginAccountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            <Box style={webStyle.main_Box}>
                <Box component={Container} maxWidth='xs' style={{ marginTop: '42px' }} >
                    <img src={Logo} alt="" style={webStyle.img_logo} />
                    <div style={webStyle.flex_Container}>
                            <Box display='flex' flexDirection='column'  >
                                <img src={LoginImg} style={webStyle.login_img} />
                            </Box>
                        <div>
                            <Box component={Paper} style={webStyle.form_box}>
                                <Typography variant="h6" style={{
                                    fontWeight: 'bold',
                                    fontSize: '30px',
                                    color: 'white',
                                    fontFamily:'Roboto Mono',
                                }}>Welcome back...</Typography>
                                <Typography style={webStyle.text_Subtitle1}>Please enter your email & password</Typography>
                                <Box py={2}>
                                    <TextField fullWidth
                                        data-test-id="emailInput"
                                        variant="outlined"
                                        value={this.state.email}
                                        error={this.state.emailError}
                                        onChange={(e: any) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                        InputProps={{
                                            style: { backgroundColor: 'white', height: '8vh' },
                                            name: 'inputProps',
                                            placeholder: 'Email Address',
                                        }}
                                    />
                                </Box>
                                <Box style={webStyle.password_box}>
                                    <TextField fullWidth
                                        variant="outlined"
                                        value={this.state.password}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        error={this.state.passwordError}
                                        onChange={(e: any) => {
                                            this.setState({ password: e.target.value })
                                        }}
                                        onKeyDown={this.handleKeyDown}
                                        InputProps={{
                                            style: { backgroundColor: 'white', height: '8vh' },
                                            name: 'inputProps',
                                            placeholder: 'Password',
                                            endAdornment: (
                                                <IconButton
                                                    data-test-Password="ShowPassword"
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                </Box>
                                <Box style={webStyle.forgot_box}>
                                    {this.state.errorEmailSelect &&
                                        <Typography style={webStyle.error_txt}>{this.state.emailError}</Typography>
                                    }
                                    {this.state.errorPwdSelect &&
                                        <Typography style={webStyle.error_txt}>{this.state.passwordError}</Typography>

                                    }
                                    <Typography style={{
                                        textAlign: 'end',
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        color: 'white',
                                        fontFamily:'Roboto Mono',
                                    }}
                                        onClick={() => {
                                            this.props.navigation.navigate('ForgotPassword');
                                        }}
                                    >
                                        Forgot Password?
                                    </Typography>
                                </Box>
                                <Button
                                    data-test-id='LogIn'
                                    style={webStyle.login_Btn}
                                    onClick={this.loginSubmit}
                                    variant="contained" color="primary">sign in</Button>
                            </Box>
                        </div>
                    </div>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {

    main_Box: {
        backgroundColor: '#080B27',
        height: '100vh',
        paddingLeft: '32px',
        paddingRight: '32px'
    },
    flex_Container:{
        display:'flex', 
        justifyContent:'center',
        marginTop:'-30px', 
        gap:'138px'
    },
    img_logo: {
        height: '27vh',
        width: '27%'
    },
    title_Text: {
        fontWeight: 'bold',
        fontSize: '2.1rem',
        fontFamily:'Roboto Mono'
    },
    text_Subtitle2: {
        color: '#565261',
        fontSize: '15',
        fontFamily:'Roboto Mono'
    },
    text_Subtitle1: {
        color: 'white',
        fontSize: '15',
        fontFamily:'Roboto Mono'
    },
    text_h6: {
        fontWeight: 'bold',
        fontSize: '30px',
        fontFamily:'Roboto Mono',
    },
    forgot_txt: {
        textAlign: 'end',
        fontSize: '10px',
        fontWeight: 'bold',
        cursor: 'pointer',
        fontFamily:'Roboto Mono',
    },
    error_txt: {
        fontSize: '10px',
        flex: 1,
        color: 'black',
        fontFamily:'Roboto Mono',
    },
    forgot_box: {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: 10
    },
    form_box: {
        backgroundColor: '#002CFF ',
        width: 310,
        borderRadius: 17,
        padding: 24,

    },
    password_box: {
        paddingTop: 16,
        paddingBottom: 12,
    },
    login_Btn: {
        width: '310px',
        backgroundColor: '7700A6',
    },
    login_img: {
        height: '43vh',
        marginTop:'50px'
    },
};
// Customizable Area End


