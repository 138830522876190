import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  enableField: boolean;
  // Customizable Area Start
  email: string;
  password: any;
  FieldError: any;
  error: boolean;
  emailError: any;
  passwordError: any;
  showPassword: boolean;
  errorEmailSelect: any;
  errorPwdSelect: any;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoginAccountController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  LoginCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      enableField: false,
      // Customizable Area Start
      email: '',
      password: '',
      FieldError: '',
      error: false,
      showPassword: false,
      emailError: '',
      passwordError: '',
      errorEmailSelect: false,
      errorPwdSelect: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId)

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.LoginCallId) {
          if (!responseJson.errors) {
             this.props.navigation.navigate("MainScreen") 
             localStorage.setItem("loginSuccessToken", responseJson?.meta?.token)
          }
          else {
             if (responseJson?.errors[0]?.failed_login === 'The email address entered is incorrect') {
              this.setState({
                emailError: responseJson?.errors[0]?.failed_login,
                passwordError: '',
                errorEmailSelect: true,
                errorPwdSelect: false,
              })
            }
            else if (responseJson?.errors[0]?.failed_login === 'The password entered is incorrect') {
              this.setState({
                passwordError: responseJson?.errors[0]?.failed_login,
                emailError: '',
                errorPwdSelect: true,
                errorEmailSelect: false,
              })
            }

          }
        }
      }

    }

    // Customizable Area End
  }

  // Customizable Area End

  // web events

  // Customizable Area Start
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  handleKeyDown = async(e:any)=>{
    if (e.key === "Enter") {
      e.preventDefault();
      this.loginSubmit();
    }

  }


  loginSubmit = async () => {
   
    const header = {
      "Content-Type": "application/json",
    };
    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.LoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/logins`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  
  }
}


  // Customizable Area End

