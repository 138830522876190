
import React from "react";

import {
    Container,
    Box,
    Paper,
    Button,
    Typography,
    // Customizable Area Start

    Grid

    // Customizable Area End

} from "@material-ui/core";

// Customizable Area Start

import { createTheme} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
export const Logo = require("../../global_assets/Logos.png");
export const LoginImg = require("../../global_assets/Logo.png");

    const theme = createTheme({
        palette: {
          primary: {
              contrastText: "#fff",
              main: "#fff",
          },
        },
        typography: {
            subtitle1: {
              margin: "20px 0px",
            },
          h6: {
            fontSize: '30px',
            fontWeight: 'bold',
            color:'white',
          },
          h5:{
            fontSize: '28px',
            fontWeight: 'bold',
            lineHeight: 'normal'
          }
        },
      });
// Customizable Area End

import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController.web";

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {

        super(props);

        // Customizable Area Start

        // Customizable Area End
    }
    render() {
        // Customizable Area Start

        const { classes } = this.props;

        // Customizable Area End

        return (

            // Customizable Area Start

            <Box style={webStyle.main_Box}>
                <Box component={Container} maxWidth='lg' style={{marginTop:'52px'}} >
                <img src={Logo} alt="" style={webStyle.img_logo} />
                    <div style={webStyle.flex_Container}>
                        <div style={{display:'flex', justifyContent:'center',marginTop:'-30px', gap:'138px'}}> 
                             <Box display='flex' flexDirection='column'  >
                                <img src={LoginImg} style={webStyle.login_img} />
                            </Box>
                        </div>
                        <div >
                            {
                                this.state.isForgot ?
                                    <Box component={Paper} style={webStyle.form_box}>
                                        <Typography variant="h6" style={{
                                             fontSize: '30px',
                                             fontWeight: 'bold',
                                             color:'white',
                                             fontFamily:'Roboto Mono',
                                             
                                        }}>Forgot Password?</Typography>
                                        <Typography style={webStyle.text_Subtitle1}>Enter your registered email address</Typography>
                                        <Typography style={webStyle.text_Subtitle1}>below to receive password</Typography>
                                        <Box pt={2} py={1}>
                                            <TextField fullWidth
                                                data-test-id='inputEmail'
                                                error={this.state.forgotError}
                                                variant="outlined"
                                                value={this.state.email}
                                                onKeyDown={this.handleKeyDown}
                                                onChange={(e: any) => this.setState({ email: e.target.value })}
                                                InputProps={{
                                                    style:{backgroundColor:'white' , height:'8vh'},
                                                    placeholder: 'Email Address',
                                                }}
                                            />
                                            <Typography style={webStyle.error_txt}>{this.state.forgotError}</Typography>
                                        </Box>
                                        <Box style={{ marginTop: '10px' }}>
                                            <Button
                                                data-test-id='send'
                                                onClick={this.forgotpasswowrrd}
                                                style={webStyle.login_Btn}
                                                variant="contained" color="primary">send</Button>
                                        </Box>
                                    </Box> :
                                    <Box component={Paper} style={webStyle.form_box}>
                                        <Box width={326}>
                                            <Typography variant="h5" style={{color:'white',fontFamily:'Roboto Mono',}}>{this.state.successMsg}</Typography>
                                        </Box>
                                        <Box style={{ marginTop: '30px' }}>
                                            <Button
                                                data-test-id='sendBtn'
                                                style={webStyle.login_Btn}
                                                onClick={() => {
                                                    this.props.navigation.navigate('LoginAccount');
                                                }}
                                                variant="contained" color="primary">Sign in again</Button>
                                        </Box>
                                    </Box>
                            }
                        </div>
                    </div>
                </Box>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {

    main_Box: {
        backgroundColor: '#080B27',
        height: '100vh',
        paddingLeft: '32px',
        paddingRight: '32px'
    },
    flex_Container:{
        display:'flex', 
        justifyContent:'center',
        marginTop:'-30px', 
        gap:'138px'
    },
    img_logo: {
        height: '27vh',
        width: '27%'
    },
    text_Subtitle1: {
        color: 'white',
        fontSize: '15',
        fontFamily:'Roboto Mono',
    },
    text_h6: {
        fontWeight: 'bold',
        fontSize: '30px'
    },
    forgot_txt: {
        textAlign: 'end',
        fontSize: '10px',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    error_txt: {
        fontSize: '10px',
        flex: 1,
        color: 'black',
        marginTop:'10px'
    },
    forgot_box: {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: 10
    },
    form_box: {
        backgroundColor: '#002CFF ',
        width: 350,
        borderRadius: 17,
        padding: 24,

    },
    password_box: {
        paddingTop: 16,
        paddingBottom: 12,
    },
    login_Btn: {
        width: '350px',
        backgroundColor: '7700A6',
    },
    login_img: {
         height: '39vh',
        marginTop:'50px'
    },
};
// Customizable Area End
