import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const baseUrl = process.env.REACT_APP_baseUrl;
import { v4 as uuidv4 } from 'uuid'
// Customizable Area Start
// @ts-ignore
import axios, * as others from 'axios';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  uploadedFileCount?:any;
  clearFiles?:boolean;
  resetClearState:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedFile: any,
  showUploads: boolean,
  isColor: boolean,
  displayFiles: any,
  progress: any,
  filesArray: any,
  dragActive: any,
  isBtnActive: boolean,
  uploadFileDisplay: any,
  percentCompleted:any,
  finalUploadfile:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FileAttachmentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  uploadApiId: any
  RemoveFileId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedFile: null,
      showUploads: false,
      displayFiles: [],
      progress: 0,
      percentCompleted: 0,
      filesArray: [],
      isColor: false,
      dragActive: false,
      isBtnActive: true,
      uploadFileDisplay: [],
      finalUploadfile:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentWillMount() {
    // Customizable Area Start
    // setTimeout(() => this.setState({ progress: 10 }), 2000);
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      // this.setState({ progress: 100 })
      this.setState({ isColor: true })
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    console.log("myapiRequestCallId", apiRequestCallId)

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.uploadApiId) {
          if (responseJson.message) {
            console.log("array of display files here", this.state.uploadFileDisplay)
          }
          else {
            this.parseApiErrorResponse(responseJson);
          }
        }
       
      }

    }
    // Customizable Area End
  }

  // Customizable Area End

  // web events

  // Customizable Area Start

  async componentWillReceiveProps(nextProps: any, nextContext: any) {
    console.log(nextProps.clearFiles, "nextProps.clearFiles")

    if(nextProps.clearFiles){
      console.log(nextProps.clearFiles, "nextProps.clearFiles iiner")
      this.props.resetClearState(false)
      this.setState({
        finalUploadfile: [],
        showUploads: false,
        uploadFileDisplay: []
      })
    }
  }
  
  ondroped = (acceptedFiles: any) => {
    this.onFileUpload(acceptedFiles);
    this.setState({ showUploads: true, isBtnActive: false })
  }
  
  removeExtension = (filename: String) => {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
  }

  onFileChange = (e: any) => {
    this.onFileUpload(e.target.files);
    this.setState({ showUploads: true, isBtnActive: false })
  };

  onFileUpload = (file: any) => {
    const uploadToken: any = localStorage.getItem("loginSuccessToken")
    let x:any = [];
     const formData = new FormData();
     for (let i = 0; i < file.length; i++) {
      const filez = file[i];
      // if (filez.size > 10 * 1024 * 1024) {
      //   // file size validation, here we are allowing file size up to 1MB
      //   alert(`${filez.name} is not gretwe than 10`);
      //   continue;
      // }
       formData.append('data[documents][]', file[i]);
       x.push({ id: uuidv4(), data: file[i], p: 0 })
     }
     this.setState({ uploadFileDisplay: [...this.state.uploadFileDisplay, ...x] }, () => {
     
     var p:any;
     const headers = {
       'Content-Type': 'multipart/form-data',
       'token': uploadToken
     }
     let url = baseUrl ? `${baseUrl}` : `https://brock-276470-ruby.b276470.dev.eastus.az.svc.builder.cafe`
       axios
         .post(`${url}/account_block/accounts/upload_document`, formData,{
           headers: headers,
           onUploadProgress: (progressEvent:any) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log('Upload Progress: ' + percentCompleted + '%');
            p = x.map((d:any, i:any) => {
              // let pp = (d.data.size/progressEvent.total ) * 100  // 40%  yogdan 
              // let aaya=progressEvent.loaded*pp/100
              // let pr=(aaya/d.data.size)*100
              let pr = Math.floor((progressEvent.loaded / d.data.size) * 100)   //
              return { ...d, p: pr > 100 ? 100 : pr }
            })
            console.log(p, " this is p");
            this.setState({ uploadFileDisplay: [...p] })
          }
        })
       .then((response :any)=> {
         console.log("formData", response.data.data.attributes);
         let pp = p.map((d:any, i:any) => {
          let sameData = response.data.data.attributes.documents.find((dd:any) => d.data.name === dd.file_name)
          if (sameData) {
            return { ...d, blob_id: sameData.blob_id }
          } else {
            return d
          }
        })
        this.setState({ uploadFileDisplay: [] })
        this.setState({ finalUploadfile: [...this.state.finalUploadfile, ...pp] })
        this.props.uploadedFileCount([...this.state.finalUploadfile, ...pp])
        // setuploadFile([...uploadFile, ...pp])
        // document.getElementById('message').innerHTML = response.data.message;
      })
       .catch((error:any) => {
         console.error(error);
       });
      });
  };

  handleDocumentRemove = (Item: any,deleteId:any) => {
    let x = this.state.finalUploadfile.filter((d:any, i:any) => d.id !== deleteId)
    this.setState({ finalUploadfile: [...x] })
    let removeToekn = localStorage.getItem("loginSuccessToken")
    const header = {
      "Content-Type": "application/json",
      "token": removeToekn
    };
    const attrs = {
      blob_id: Item
    };
    const data = {
      attributes: attrs,
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.RemoveFileId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/remove_document`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.props.uploadedFileCount(this.state.finalUploadfile.filter((ele: { blob_id: any; }) => Item !== ele.blob_id))
    return true;
  }
  // Customizable Area End
}
