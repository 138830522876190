import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
const UploadImg = require("../../global_assets/upload_icon.png")
const pdfIcon = require("../assets/image_pdf.png")
const FileDelete = require("../assets/fileDelete.png")

{/* @ts-ignore */ }
import ProgressBar from "@ramonak/react-progress-bar";
{/* @ts-ignore */ }
import Dropzone from 'react-dropzone';


// Customizable Area Start


// Customizable Area End

import FileAttachmentController, {
  Props,
} from "./FileAttachmentController.web";

export default class FileAttachment extends FileAttachmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={webStyle.Upload_div}>
          <Box p={2} sx={{ flexGrow: 1 }} style={{
            height: '38vh',
            textAlign: 'center',
            backgroundColor: 'white',
            overflowY: 'hidden',
            borderRadius: '17px',
            width: '193px',
            padding: '15px',
          }}>
            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '1.4rem', fontFamily: 'Roboto Mono' }}>Upload your documents</Typography>
            <Dropzone data-test-id="txtInput" onDrop={(acceptedFiles: any) => this.ondroped(acceptedFiles)}>
              {/* @ts-ignore */}
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input  {...getInputProps()} multiple type="file" accept='application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document' />
                    <Box my={2}>
                      <img src={UploadImg} alt={UploadImg} style={{width: '84px'}}/>
                    </Box>
                    <Box my={2}>
                      <Typography variant="subtitle1" style={{ color: '#807670', fontFamily: 'Roboto Mono' }} >Drag and drop your documents here</Typography>
                    </Box>
                  </div>
                </section>
              )}
            </Dropzone>
            <Button variant="contained"
              component="label"
              style={webStyle.uploadButton}
            >
              Upload document(s)
              <input type="file" data-test-id = "UploadFile" onChange={this.onFileChange} accept='application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document' multiple hidden />
            </Button>
          </Box>
          {this.state.showUploads && (
            <div style={{
              flexGrow: 1,
              height: '38vh',
              textAlign: 'center',
              backgroundColor: 'white',
              borderRadius: '17px',
              width: '193px',
              padding: '15px',
              paddingRight:'0px',
              paddingTop:'0px',
              paddingBottom:'30px',
              overflow:'hidden'
            }} >
              <Box p={2} style={{
                height: '38vh',
                textAlign: 'center',
                backgroundColor: 'white',
                overflowY: 'auto',
                borderRadius: '14px',
                width: 'auto',
                padding: '15px',
              }} >
                <Typography variant="h5" style={{
                  fontSize: '1rem',
                  textAlign: "left",
                  padding: "10px",
                  fontFamily: 'Roboto Mono'
                }}>Uploading Documents</Typography>
                {this.state.uploadFileDisplay.length > 0 && this.state.uploadFileDisplay?.map((file: any, index: any) => {
                  return (
                    <>
                     
                        <div style={webStyle.pdfName} key={index}>
                          <div><img style={webStyle.pdfIcon} src={pdfIcon} alt={pdfIcon} /></div>
                          <div style={{ flexGrow: 1, display: 'inline-block' }}>
                            <div style={{ display: 'flex', fontSize: '1rem', color: 'rgb(128, 118, 112)' }}>
                              <p style={{ flexGrow: 1, textAlign: 'start', fontSize: '0.8rem', fontFamily: 'Roboto Mono' }}>{this.removeExtension(file.data.name.substring(0,15))}</p>
                              <p style={{ flexGrow: 1, textAlign: 'end', color: file.p < 80 ? 'red' : 'green' }}>{file.p}%</p>
                            </div>
                            <ProgressBar customLabelStyles={{display:'none'}} height="9px" bgColor={file.p < 80 ? 'red' : 'green'} 
                              completed={file.p}   />
                          </div>
                          <div style={{ marginTop: '10px', marginLeft: '12px', transform: 'scale(1.2)' }}>
                            <img src={FileDelete} style={{height:'3vh' , marginTop:'17px', cursor: 'pointer',}}/>
                          </div>
                        </div>
                    </>
                  )
                })}
                 {this.state.finalUploadfile.length > 0 && this.state.finalUploadfile?.map((file: any, index: any) => {
                  return (
                    <>
                     
                        <div style={webStyle.pdfName} key={index}>
                          <div><img style={webStyle.pdfIcon} src={pdfIcon} alt={pdfIcon} /></div>
                          <div style={{ flexGrow: 1, display: 'inline-block' }}>
                            <div style={{ display: 'flex', fontSize: '1rem', color: 'rgb(128, 118, 112)' }}>
                              <p style={{ flexGrow: 1, textAlign: 'start', fontSize: '0.8rem', fontFamily: 'Roboto Mono' }}>{this.removeExtension(file.data.name.substring(0, 15))}</p>
                              <p style={{ flexGrow: 1, textAlign: 'end', color: file.p < 80 ? 'red' : 'green' }}>{file.p}%</p>
                            </div>
                            <ProgressBar customLabelStyles={{display:'none'}} height="9px" bgColor={file.p < 80 ? 'red' : 'green'} 
                              completed={file.p}   />
                          </div>
                          <div style={{ marginTop: '10px', marginLeft: '12px', transform: 'scale(1.2)' }}>
                            <img src={FileDelete} data-test-id="deleteFile" style={{height:'3vh' , marginTop:'17px', cursor: 'pointer',}}
                            onClick={() => this.handleDocumentRemove(file?.blob_id,file.id)}
                            />
                          </div>
                        </div>
                    </>
                  )
                })}
              </Box>
            </div>
          )}
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  Upload_div: {
    display: 'flex',
    gap: '30px'
  },
  uploadButton: {
    backgroundColor: '#7700A6',
    color: 'white',
    fontFamily: 'Roboto Mono'
  },
  pdfName: {
    marginLeft: '-15px',
    borderRadius: '17px',
    marginBottom: '12px',
    display: 'flex'
  },
  pdfIcon: {
    height: '3rem',
    width: '2.5rem',
    padding: "0.5rem",
    marginRight: "0.25rem"
  },
}

// Customizable Area End