import {
    Box,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
{/* @ts-ignore */}
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface CustomDocsProps {
    link: string;
}

function highlightPattern(text: string, pattern: any) {
    return text.replace(pattern, (value) => `<mark>${value}</mark>`);
}


const CustomDocViewer = ({ link }: CustomDocsProps) => {
    const [numPages, setNumPages] = useState(null);

    const textRenderer = useCallback(
        (textItem) => highlightPattern(textItem.str, "Simple"),
        ["Simple"]
      );

    // @ts-ignore
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <Box style={{ display: 'flex', justifyContent: 'center', height: "32em", overflowY: "scroll", overflowX: "hidden", width: "100%" }}>
            <Document file="https://www.africau.edu/images/default/sample.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                    <Box key={index + 1} style={{ marginBottom: "7px" }}>
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} customTextRenderer={textRenderer} />
                    </Box>
                ))}
            </Document>
        </Box>
    )
}
export default React.memo(CustomDocViewer);
