import React from "react";

// Customizable Area Start
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Box, Button, Typography, Tab, Tabs, AppBar, TextField } from "@material-ui/core";
import { createTheme, withStyles, ThemeProvider } from "@material-ui/core/styles";
// import DocViewer, { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import DocOpenerController from "./DocOpenerController.web";
import SearchIcon from '@material-ui/icons/Search';
const NotFoundImg = require("../assets/image_notofund.png");
const CancelImg = require("../../global_assets/cancelImg.png");

import Grid from "@material-ui/core/Grid"
import "./Document.css"
//@ts-ignore
import CustomDocViewer from '../../../components/src/CustomDocViewer';

// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
// Customizable Area End


import { Props } from "./DocumentOpenerCommonController";

export default class DocumentOpener extends DocOpenerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  SelectAllCheckBox = withStyles({
    root: {
      color: "#7700A6",
      '&$checked': {
        color: "#7700A6",
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <>
        <ThemeProvider theme={theme}>
          <Box sx={{ flexGrow: 1 }} style={{ display: 'flex', paddingBottom: '2rem', justifyContent: 'space-between', backgroundColor: '#080B27', fontFamily: 'Roboto Mono' }}>
            <Box style={{ height: "calc(40em * 1.0325)", display: 'flex', flexDirection: 'column', marginTop: '-2rem', marginLeft: '3rem', backgroundColor: 'white', width: '30%', borderRadius: '0.75rem' }}>
              <Box style={{ flex: this.state.allKeywords.length > 0 ? 2 : 1, marginBottom: '25px', 
                borderTopLeftRadius: '0.75rem', 
                borderTopRightRadius: '0.75rem',
                borderBottom: '1px dashed gray',
                paddingBottom: '25px'
               }} >
                <Typography variant='subtitle2' style={{ fontFamily: 'Roboto Mono', fontSize: '1rem', padding: '1rem' }}>Keywords</Typography>

                <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '0.75rem', marginBottom: '0.75rem', marginRight: '0.75rem' }}>
                  <TextField variant="standard" margin="normal" required id="keywordSearch"
                    data-test-id="keyword_search"
                    name="keywordSearch" autoFocus placeholder="Enter Keyword..."
                    value={this.state.keywordText}
                    onChange={this.handleTextChange}
                    onKeyPress={this.handleKeywordAddition}
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      disableUnderline: true,
                    }}
                    style={{ boxShadow: '0 2px 4px rgb(0 0 0 / 0.2)', padding: '10px' }}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button 
                  data-test-id="apply_keyword"
                  variant="contained" 
                  style={webStyle.applyButton}
                  onClick={() => this.handleKeywordAddition('applied')}
                >
                  apply
                </Button>
                </div>
              </Box>
             { (this.state.allKeywords.length > 0) && <Box style={{
                 overflow: 'auto',
                 overflowX: 'hidden',
                 marginBottom: '10px',
                 borderBottom: '1px dashed gray',
                 flex: 5
              }}>
                <Box margin='25px' display='flex' flexWrap='wrap' mt={2} justifyContent='center' alignItems='center'>
                  <Box style={webStyle.select_all}>
                    <Typography>Select All</Typography>
                    <this.SelectAllCheckBox
                    checked={this.state.checkedKeyword}
                      onChange={this.onChangeSelectAllCheckbox}
                    />
                  </Box>
                  {this.state.allKeywords.map((item: any, index: any) => {
                    return (
                      <React.Fragment key={item.id}>
                        {item &&
                          <Box p={1} key={item.id} style={webStyle.keyword_Add_box}>
                            <Box display='flex' alignItems='center'>
                              <Checkbox
                                data-test-id="item_check_box"
                                // checked={this.state.checkedKeyword}
                                checked={item.checked}
                                onChange={(e) => this.onChangeCheckbox(e, item.id)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                              <Typography 
                                data-test-id="item_check_box_name" 
                                variant='subtitle2' 
                                style={{ fontSize: '16px', wordBreak: 'break-word' }}
                                >
                                {item.name}
                              </Typography>
                            </Box>
                            <img src={CancelImg}
                              data-test-id="remove"
                              style={webStyle.keyword_remove_img}
                              onClick={() => {
                                  this.handleKeywordRemove(item.id)
                                }
                              }
                            />
                          </Box>
                        }
                      </React.Fragment>
                    )
                  })}
                </Box>
              </Box>
              }
              <Box style={{ alignSelf: 'center', flex: this.state.allKeywords.length === 0 ? 3 : 1, display: 'table-cell', verticalAlign: 'middle' }}>
               { (this.state.allKeywords.length === 0) && <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={NotFoundImg} />
                </div>
               } 
                <div style={{ display: 'flex', justifyContent: 'center', padding: '15px' }}>
                  <Typography data-test-id="keywords_not_found" variant='subtitle2' style={{ fontSize: '0.8rem' }}>Keywords Not Found.</Typography>
                </div>
              </Box>
            </Box>

            <Box style={{ fontFamily: 'Roboto Mono', marginTop: '-2rem', marginRight: '3rem', marginLeft: '1.3rem', borderRadius: '0.75rem', width: '70%' }}>
              <Grid container spacing={2} style={{ marginBottom: '5px' }}>
                <Grid item xs={8}>
                  <AppBar position="static" style={{ background: "#333545", minHeight: 53, borderRadius: '0.25rem' }}>
                    <Tabs value={this.state.value} onChange={this.handleChange}
                      variant="scrollable" scrollButtons="off" indicatorColor="primary"
                      style={{ minHeight: 53}} aria-label="scrollable tabs">
                      {
                        this.state.responseData && this.state.responseData.map((files, index) => {
                          return (
                            <Tab
                              label={this.removeExtension(files?.file_name)}
                              key={index}
                              onClick={() => { this.setState({ indexes: index }), console.log("indexes", this.state.indexes) }}
                              style={{color: "#fff", fontSize: 13, minHeight: 43, margin: 'auto', marginTop: '7px', padding: '0.25rem' }}
                            />
                          );
                        })}
                    </Tabs>
                  </AppBar>
                </Grid>
                <Grid item xs={4}>
                  <Button style={{ width: '100%', backgroundColor: '#7700A6', color: 'white', height: '53px', fontSize: '0.9rem' }} variant="contained" component="label" data-test-id='Uploadbtn'>
                    + Upload Document
                    <input type="file" data-test-id="multi-file" multiple accept="application/pdf" onChange={this.onFileChange} hidden />
                  </Button>
                </Grid>
              </Grid>
              <Box sx={webStyle.mainWrapper}>
                {this.state.responseData.filter((matchedIndex, index) => {
                  return (index === this.state.indexes)
                }).map((file, index) => {
                  return ( 
                    <Box key={index} style={{ width: '100%', borderRadius: '0.75rem' }}>
                      {/* @ts-ignore */}
                      {file.blob_rl && (
                        <CustomDocViewer
                          link={file.blob_rl}
                        />
                      )}
                    </Box>
                  )
                })
                }
                <Button 
                  style={{ fontFamily: 'Roboto Mono', width: '50%', marginTop: '15px', marginBottom: '15px', backgroundColor: '#B5E9FF', color: 'black', height: '53px', fontSize: '0.9rem' }} 
                  variant="contained" component="label"
                  onClick={this.handleDownload}
                  data-test-id='Downloadbtn'
                >
                  <strong>Download</strong>
                </Button>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>

      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto Mono",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    justifyContent: 'center'
  },
  buttonStyle: {
    height: "2.813rem",
    marginTop: 10,
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  applyButton: {
    backgroundColor: '#7700A6',
    color: 'white',
  },
  select_all: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: '8%',
    marginRight: '3%',
  },
  keywords_container: {
    height: '50%',
    overflow: 'auto',
    overflowX: 'hidden',
    borderBottom: '1px dashed gray',
    borderTop: '1px dashed gray'
  },
  keyword_Add_box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#e9e4f8',
    border: '1px solid #8762f3',
    borderRadius: '6px',
    width: '80%',
    margin: '10px'
  },
  keyword_remove_img: {
    marginRight: '1px',
    height: '3vh'
  }
};

// Customizable Area End
