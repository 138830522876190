import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Dialog from "@material-ui/core/Dialog";
import { createTheme } from "@material-ui/core/styles";
import TopLogoLogoutController from "./TopLogoLogoutController.web";
export const Logo = require("../../global_assets/Logos.png");
export const CrossIcon = require("../../global_assets/crossblack.png");

const theme = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: "17px",
      },
    },
  },
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class TopLogoLogout extends TopLogoLogoutController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={{ overflowX: "hidden" }}>
          <Box>
            <Box style={webStyle.mainWrapper} display="flex">
              <Box flexGrow={2}>
                <img
                  onClick={() => this.handleGoBack()}
                  src={Logo}
                  alt=""
                  style={webStyle.img_logo}
                />
              </Box>
              <Box style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  style={webStyle.go_backBtn}
                  onClick={() => this.handleGoBack()}
                >
                  GO BACK
                </Button>
                <Dialog
                  PaperProps={{
                    style: { borderRadius: 17, boxShadow: "none" },
                  }}
                  open={this.state.goBackModal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <Box style={{ backgroundColor: "#002CFF" }}>
                    <Box display="flex" justifyContent="flex-end" mr={2} mt={2}>
                      <img
                        src={CrossIcon}
                        alt=""
                        style={webStyle.cross_img}
                        onClick={this.handleGoBackClose}
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      p={4}
                      width={420}
                    >
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="h5"
                          style={{ fontFamily: "Roboto Mono", color: "white" }}
                        >
                          Are you sure you want to{" "}
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ fontFamily: "Roboto Mono", color: "white" }}
                        >
                          to leave without downloading
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ fontFamily: "Roboto Mono", color: "white" }}
                        >
                          {"document(s)"}?
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        style={{ gap: "52px", marginTop: "50px" }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          style={webStyle.no_Btn}
                          onClick={this.handleGoBackClose}
                        >
                          No
                        </Button>
                        <Button
                          color="primary"
                          data-test-id="YesBtn"
                          variant="contained"
                          style={webStyle.yes_Btn}
                          onClick={this.clearDoc}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Dialog>
                <Button
                  data-test-id="LogoutBtn"
                  variant="contained"
                  style={webStyle.logout_Btn}
                  onClick={this.handleClickOpen}
                >
                  Sign out
                </Button>
                <Dialog
                  PaperProps={{
                    style: { borderRadius: 17, boxShadow: "none" },
                  }}
                  open={this.state.open}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <Box style={{ backgroundColor: "#002CFF" }}>
                    <Box display="flex" justifyContent="flex-end" mr={2} mt={2}>
                      <img
                        src={CrossIcon}
                        alt=""
                        style={webStyle.cross_img}
                        onClick={this.close}
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      p={4}
                      width={420}
                    >
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="h5"
                          style={{ fontFamily: "Roboto Mono", color: "white" }}
                        >
                          Are you sure you want{" "}
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ fontFamily: "Roboto Mono", color: "white" }}
                        >
                          to sign out without{" "}
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ fontFamily: "Roboto Mono", color: "white" }}
                        >
                          {"downloading document(s) ?"}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        style={{ gap: "52px", marginTop: "50px" }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          style={webStyle.no_Btn}
                          onClick={this.close}
                        >
                          No
                        </Button>
                        <Button
                          color="primary"
                          data-test-id="YesBtn"
                          variant="contained"
                          style={webStyle.yes_Btn}
                          onClick={this.logout}
                        >
                          Yes
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Dialog>
              </Box>
            </Box>
          </Box>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    height: "100px",
    background: "#080B27",
    padding: "0px 52px 50px 41px",
    borderRadius: "0",
  },
  go_backBtn: {
    marginRight: "12px",
    backgroundColor: "#7990FF",
    color: "white",
    fontFamily: "Roboto Mono",
  },
  logout_Btn: {
    backgroundColor: "#7700A6",
    color: "white",
    fontFamily: "Roboto Mono",
  },
  no_Btn: {
    backgroundColor: "#7700A6",
    color: "white",
    width: "114px",
    fontFamily: "Roboto Mono",
  },
  yes_Btn: {
    backgroundColor: "#7990FF",
    color: "white",
    width: "114px",
    fontFamily: "Roboto Mono",
  },
  img_logo: {
    height: "110%",
    width: "20%",
    marginLeft: "9%",
  },
  cross_img: {
    height: "5vh",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    fontFamily: "Roboto Mono",
  },
};
// Customizable Area End
